const Redirectiondata = [
  {
    pasturl: "/support-us",
    currenturl: "/iptv-customer-support"
  },
  {
    pasturl: "/copy-of-packages-1",
    currenturl: "/subscription-packages-iptv"
  },
  {
    pasturl: "/packages/hindi-gold-hd-suggestive-bouquet",
    currenturl: "/subscription-packages-iptv"
  },
  {
    pasturl: "/blogs/how-can-we-onvert-old-into-an-iptv",
    currenturl: "/blogs/how-can-we-convert-old-tv-into-an-iptv"
  },
  {
    pasturl: "/blogs//blogs/how-can-we-convert-old-into-an-iptv",
    currenturl: "blogs/how-can-we-convert-old-tv-into-an-iptv"
  },
  {
    pasturl: "/iptv-app/https//play.google.com/store/apps/details?id=com.onnet.systems.iptv.skypro",
    currenturl: "/iptv-app"
  },
  {
    pasturl: "/404error",
    currenturl: "/home"
  },
  {
    pasturl: "/blogs//blogs/iptv-next-generation-streaming-technology",
    currenturl: "/blogs"
  },
  {
    pasturl: "/iptv-app/https//www.skypro.co.in/subscription-packages-iptv",
    currenturl: "/subscription-packages-iptv"
  },
  {
    pasturl: "/packages/hindi-bronze-sd-suggestive-bouquet",
    currenturl: "/subscription-packages-iptv"
  },
  {
    pasturl: "/subscription-packages-iptv/hindi-bronze-sd-suggestive-bouquet",
    currenturl: "/subscription-packages-iptv"
  },
  {
    pasturl: "/product-page/i-m-a-product-2",
    currenturl: "/subscription-packages-iptv"
  },
  {
    pasturl: "/copy-of-basic-service-tier",
    currenturl: "/subscription-packages-iptv"
  },
  {
    pasturl: "/copy-of-silver-suggestive-4",
    currenturl: "/subscription-packages-iptv"
  },
  {
    pasturl: "/packages/hindi-silver-hd-suggestive-bouquet",
    currenturl: "/subscription-packages-iptv"
  },
  {
    pasturl: "/blog-single.html",
    currenturl: "/blogs"
  },
  {
    pasturl: "/blogs//blogs/iptv-vs-cable-tv-differences",
    currenturl: "/blogs/iptv-vs-cable-tv-differences"
  },
  {
    pasturl: "/copy-of-iptv",
    currenturl: "/home"
  },
  {
    pasturl: "/product-page/sony-hd",
    currenturl: "/subscription-packages-iptv"
  },
  {
    pasturl: "/product-page/i-m-a-product-11",
    currenturl: "/subscription-packages-iptv"
  },
  {
    pasturl: "/packages",
    currenturl: "/subscription-packages-iptv"
  },
  {
    pasturl: "/about-1",
    currenturl: "/about-us"
  },
  {
    pasturl: "/copy-of-packages",
    currenturl: "/subscription-packages-iptv"
  },
  {
    pasturl: "/copy-of-privacy-policy",
    currenturl: "/privacy-policy"
  },
  {
    pasturl: "/copy-of-terms-and-conditions",
    currenturl: "/terms-and-conditions"
  },
  {
    pasturl: "/copy-of-silver-suggestive-1",
    currenturl: "/subscription-packages-iptv"
  },
  {
    pasturl: "/blogs/iptv-next-generation-streaming-technology/*",
    currenturl: "/blogs/the-next-generation-of-streaming-technology"
  },
  {
    pasturl: "/packages/hindi-gold-sd-suggestive-bouquet",
    currenturl: "/subscription-packages-iptv"
  },
  {
    pasturl: "/packages/hindi-gold-plus-sd-suggestive-bouquet",
    currenturl: "/subscription-packages-iptv"
  },
  {
    pasturl: "/subscription-packages-iptv/hindi-gold-plus-sd-suggestive-bouquet",
    currenturl: "/subscription-packages-iptv"
  },
  {
    pasturl: "/subscription-packages-iptv/hindi-platinum-hd-suggestive-bouquet",
    currenturl: "/subscription-packages-iptv"
  },
  {
    pasturl: "/subscription-packages-iptv/hindi-platinum-hd-suggestive-bouquet",
    currenturl: "/subscription-packages-iptv"
  },
  {
    pasturl: "/i-m-a-product-9",
    currenturl: "/subscription-packages-iptv"
  },
  {
    pasturl: "/subscription-packages-iptv/hindi-silver-sd-suggestive-bouquet",
    currenturl: "/subscription-packages-iptv"
  },
  {
    pasturl: "/subscription-packages-iptv/hindi-gold-sd-suggestive-bouquet",
    currenturl: "/subscription-packages-iptv"
  }
  ,
  {
    pasturl: "/subscription-packages-iptv/hindi-silver-hd-suggestive-bouquet",
    currenturl: "/subscription-packages-iptv"
  },
  {
    pasturl: "/subscription-packages-iptv/hindi-gold-hd-suggestive-bouquet",
    currenturl: "/subscription-packages-iptv"
  },
  {
    pasturl: "/subscription-packages-iptv/hindi-gold-hd-suggestive-bouquet",
    currenturl: "/subscription-packages-iptv"
  },
  {
    pasturl: "/blogs//blogs/introduction-to-iptv-internet-protocol-television",
    currenturl: "/blogs/introduction-to-iptv-internet-protocol-television"
  },
  {
    pasturl: "/blogs/how-can-we-convert-old-into-an-iptv/*",
    currenturl: "/blogs/how-can-we-convert-old-tv-into-an-iptv"
  },
  {
    pasturl: "/copy-of-terms-and-conditions",
    currenturl: "/terms-and-conditions"
  },
  {
    pasturl: "/copy-of-get-started",
    currenturl: "/home"
  },
  {
    pasturl: "/product-page/lco-platinum-hd-pack",
    currenturl: "/subscription-packages-iptv"
  },
  {
    pasturl: "/static/media/1704973447843-Disclaimer.3f280f9327e55568caf5.pdf",
    currenturl: "/iptv-compliance-regulatory"
  },
  {
    pasturl: "/sitemap",
    currenturl: "/sitemap.html"
  },
  {
    pasturl: "/about-1-1",
    currenturl: "/about-us"
  },
  {
    pasturl: "/static/media/1704973507508-APPLICATION FORM FOR ACCESS TO SKYPRO NETWORK.cf128de5497b214f753e.pdf",
    currenturl: "/iptv-compliance-regulatory"
  },
  {
    pasturl: "/static/media/1704973530993-REFERENCE INTERCONNECT OFFER CARRIAGE OF CHANNELS.40d409e18b4e69ad2d3a.pdf",
    currenturl: "/iptv-compliance-regulatory"
  },
  {
    pasturl: "/product-page/i-m-a-product-4",
    currenturl: "/subscription-packages-iptv"
  },
  {
    pasturl: "/product-page/lco-gold-pack",
    currenturl: "/subscription-packages-iptv"
  },
  {
    pasturl: "/product-page/i-m-a-product-6",
    currenturl: "/subscription-packages-iptv"
  },
  {
    pasturl: "/copy-of-silver-suggestive",
    currenturl: "/subscription-packages-iptv"
  },
  {
    pasturl: "/iptv-subscription-packages",
    currenturl: "/subscription-packages-iptv"
  },
  {
    pasturl: "/packages/hindi-silver-sd-suggestive-bouquet",
    currenturl: "/subscription-packages-iptv"
  },
  {
    pasturl: "/product-page/i-m-a-product-9",
    currenturl: "/subscription-packages-iptv"
  },
  {
    pasturl: "/static/media/1704973414722-CAF%20skypro.20d0620e092a659191ed.pdf",
    currenturl: "/iptv-compliance-regulatory"
  },
  {
    pasturl: "/static/media/1704973431038-MANUAL%20OF%20PRACTICE%20skypro.e3bc6956f820bfd717d1.pdf",
    currenturl: "/iptv-compliance-regulatory"
  },
  {
    pasturl: "/static/media/1704973461737-Declaration%20under%20Section%204(4).5762956946755e2e8586.pdf",
    currenturl: "/iptv-compliance-regulatory"
  },
  {
    pasturl: "/static/media/1704973477214-DECLARATION%20OF%20NETWORK%20CAPACITY%20FEE%201.a34a531b589ff33406bb.pdf",
    currenturl: "/iptv-compliance-regulatory"
  },
  {
    pasturl: "/iptv-subscription-packages/hindi-gold-hd-suggestive-bouquet",
    currenturl: "/subscription-packages-iptv"
  },
  {
    pasturl: "/iptv-subscription-packages/hindi-silver-hd-suggestive-bouquet",
    currenturl: "/subscription-packages-iptv"
  },
  {
    pasturl: "/iptv-subscription-packages/hindi-gold-sd-suggestive-bouquet",
    currenturl: "/subscription-packages-iptv"
  },
  {
    pasturl: "/iptv-subscription-packages/hindi-silver-sd-suggestive-bouquet",
    currenturl: "/subscription-packages-iptv"
  },
  {
    pasturl: "/iptv-subscription-packages/hindi-silver-sd-suggestive-bouquet",
    currenturl: "/subscription-packages-iptv"
  },
  {
    pasturl: "/",
    currenturl: "/home"
  },
]

export default Redirectiondata;